import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getProfile, isAdmin } from '../helpers/storage';
import { ROUND_READ_IDS, setRoundRead } from '../helpers/rounds';
import { fetchRounds } from '../api/rounds';
import Activity from './Activity';
import moment from 'moment';
import { DialogContentText } from '@material-ui/core';
const FUTURE_DAYS = 3

class ActivitiesModal extends React.Component {

  state = {
    open: false,
    rounds: []
  };

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    const params = {
      limit: 100,
      offset: 0,
      startDate: moment().startOf('day'),
      endDate: moment().add(FUTURE_DAYS, 'days').endOf('day'),
      excludedIds: ROUND_READ_IDS
    }

    const admin = isAdmin()
    if (!admin) {
      const { id } = getProfile()
      params.agent = id;
    }

    fetchRounds(params)
      .then(({ data = [] }) => {
        this.setState({ rounds: data, open: true })
      })
      .catch(err => {
        console.log(err)
        this.setState({ rounds: [] })
      })
  }

  close() {
    this.setState({ open: false });
  };

  setRead(roundId) {
    setRoundRead(roundId)
    const { rounds } = this.state;
    const newRounds = rounds.filter(({ id }) => id !== roundId)
    this.setState({ rounds: newRounds, open: newRounds.length > 0 })
  }

  render() {
    const { fullScreen } = this.props;
    const { open, rounds } = this.state;

    return (
      <div>
        <IconButton aria-label="map" size="medium" className="notifications-button" onClick={() => this.fetch()}>
          <NotificationsIcon />
        </IconButton>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="lg"
          open={open}
          onClose={() => this.close()}
          aria-labelledby="responsive-dialog-title"
          className="modal"
        >
          <DialogTitle id="responsive-dialog-title" className="modal-title">
            <NotificationsIcon />
            Promemoria visite<br /><small>(da oggi ai prossimi {FUTURE_DAYS} giorni)</small>
            <IconButton aria-label="close" onClick={() => this.close()} className="btn-close">
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent className="modal-body">
            {
              rounds.map(round => (
                <Activity
                  round={round}
                  key={round.id}
                  setRead={id => this.setRead(id)}
                />
              ))
            }
            {
              rounds.length === 0 &&
              <div className="memo-cont">
                <DialogContentText>Nessun promemoria presente</DialogContentText>
              </div>
            }
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

ActivitiesModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ActivitiesModal);