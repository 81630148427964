import axios from 'axios'
import { getProfile } from '../helpers/storage';

export const fetchAgents = () => {
  const { token } = getProfile()
  const config = {
    params: {
      filter: {
        where: { role: 'agent' }
      },
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const URL = `${process.env.REACT_APP_API_URL}/admin/agents`
  return axios.get(URL, config)
    .then(({ data = [] }) => data)
};

export const createAgent = (data) => {
  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const URL = `${process.env.REACT_APP_API_URL}/admin/agents/create`
  return axios.post(URL, data, config)
}

export const deleteAgent = ({ id }) => {
  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const URL = `${process.env.REACT_APP_API_URL}/admin/agents/${id}`
  return axios.delete(URL, config)
}

export const editAgent = (id, agent) => {
  delete agent.id

  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const URL = `${process.env.REACT_APP_API_URL}/admin/agents/${id}`
  return axios.put(URL, agent, config)
}