import axios from 'axios'

export const login = (data) => {
  const URL = `${process.env.REACT_APP_API_URL}/auth/login`
  return axios.post(URL, data)
    .then(({ data = [] }) => {
      return {
        data
      }
    })
};
