import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import SelectAgent from '../SelectAgent';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { datePickerProps } from './../../helpers/formatter';
import CircularProgress from '@material-ui/core/CircularProgress';
import MapModal from './../MapModal';

import moment from 'moment';
import 'moment/locale/it';
import SelectCompany from '../SelectCompany';
import SelectProvince from '../SelectProvince';
import SelectRegion from '../SelectRegion';

moment.locale('it');

const styles = theme => ({
  buttonClear: {
    width: '50px',
    height: '50px',
    padding: 0,
    marginTop: '23px'
  }
});

const RoundsTableToolbar = ({ classes, isLoading, startDate, endDate, onChangeStart, onChangeEnd, agent, company, province, region, referenceName, isAdmin, onChangeAgent, onChangeCompany, onChangeProvince, onChangeRegion, onChangeReferenceName, onClearFilter }) => (
  <div>
    <Typography variant="h6" className="p-title-table">
      Lista visite
      {
        isLoading && <CircularProgress />
      }
      <MapModal />
    </Typography>

    <Toolbar >

      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale="it"
        moment={moment}
      >
        <Grid container className={classes.grid} justify="flex-start">
          <Grid item xs={6} md={2}>
            <DatePicker
              label="Da"
              value={startDate}
              onChange={onChangeStart}
              {...datePickerProps}
              style={{ width: 'calc(100% - 8px)', marginTop: '16px' }}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <DatePicker
              label="A"
              value={endDate}
              onChange={onChangeEnd}
              {...datePickerProps}
              style={{ width: 'calc(100% - 8px)', marginTop: '16px' }}
            />
          </Grid>
          <Grid item xs={5} md={2}>
            {
              isAdmin &&
              <SelectAgent
                agent={agent}
                onChangeAgent={(ev) => onChangeAgent(ev.target.value)}
              />
            }
          </Grid>
          <Grid item xs={5} md={2}>
            <SelectCompany
              company={company}
              onChangeCompany={(company) => onChangeCompany(company)}
            />
          </Grid>
          <Grid item xs={1} md={4}>
            <IconButton onClick={onClearFilter} className={classes.buttonClear}>
              <ClearIcon color="primary" />
            </IconButton>
          </Grid>
          <Grid item xs={4} md={2}>
            <SelectProvince
              province={province}
              onChangeProvince={(province) => onChangeProvince(province)}
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <SelectRegion
              region={region}
              onChangeRegion={(region) => onChangeRegion(region)}
            />
          </Grid>
          {/* <Grid item xs={5} md={1}>
            <FormControl>
              <InputLabel htmlFor="reference">
                Riferimento
              </InputLabel>
              <TextField
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Riferimento"
                name="reference"
                value={referenceName}
                onChange={ev => onChangeReferenceName({ referenceName: ev.target.value })}
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </MuiPickersUtilsProvider>
    </Toolbar>
  </div >
)

export default withStyles(styles)(RoundsTableToolbar);