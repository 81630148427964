import React from 'react';
import { fetchCompanies } from '../api/companies';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Autosuggest from 'react-autosuggest';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Paper from '@material-ui/core/Paper';

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      {suggestion.name}
    </MenuItem>
  );
}

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextValidator
      InputLabelProps={{
        FormLabelClasses: {
          focused: 'form-login-field_label-focus',
        },
      }}
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
      className="input-vists"
      style={{ width: '100%' }}
      name="name"
      validators={['required']}
      errorMessages={['Campo obbligatorio']}
    />
  );
}


const styles = theme => ({
  container: {
    position: 'relative',
    marginTop: '32px',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    width: '100%',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class SelectCompany extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      companies: [],
      value: '',
    }
  }

  getCompanies({ value }) {
    if (value.length < 2) return

    fetchCompanies({ name: value, sort: 'name ASC' })
      .then(companies => this.setState({ companies }))
  }

  render() {
    const { company, onChangeCompany, classes } = this.props
    const { companies = [], value } = this.state

    return (
      <ValidatorForm onSubmit={() => { }}>
        {
          company && <div>{company.name}</div>
        }
        <Autosuggest
          onSuggestionsFetchRequested={result => this.getCompanies(result)}
          suggestions={companies}
          renderInputComponent={renderInputComponent}
          renderSuggestion={renderSuggestion}
          getSuggestionValue={suggestion => suggestion && suggestion.name}
          onSuggestionsClearRequested={() => this.setState({ companies: [], value: '' })}
          onSuggestionSelected={(event, { suggestion }) => {
            onChangeCompany(suggestion);
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          inputProps={{
            classes, placeholder: 'Cerca azienda',
            value: value,
            onChange: (ev, { newValue }) => this.setState({ value: newValue }),
            InputLabelProps: {
              shrink: true,
            },
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </ValidatorForm>

    )
  }

}

export default withStyles(styles)(SelectCompany)