import axios from 'axios'
import { getProfile } from '../helpers/storage';

export const fetchRounds = ({ limit = 10, offset = 0, startDate, endDate, agent, company, province, region, referenceName, excludedIds = [] } = {}) => {

  const { token } = getProfile()

  const where = {}
  let start
  let end

  if (startDate) {
    start = startDate.startOf('day')
    where.dateTime = {
      gte: start
    }
  }

  if (endDate) {
    end = endDate.endOf('day')
    where.dateTime = {
      lte: end
    }
  }

  if (startDate && endDate) {
    where.dateTime = {
      between: [start, end]
    }
  }

  if (agent) {
    where.user = agent
  }

  if (company) {
    where.company = company
  }

  if (province) {
    where.province = province
  }

  if (region) {
    where.region = region
  }

  if (referenceName) {
    where.referenceName = referenceName
  }

  if (excludedIds && excludedIds.length > 0) {
    where.id = {
      nin: excludedIds,
    }
  }

  const config = {
    params: {
      filter: {
        limit,
        offset,
        where,
        order: 'dateTime ASC'
      },
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const URL = `${process.env.REACT_APP_API_URL}/rounds`
  return axios.get(URL, config)
    .then(({ data = {} }) => {
      const { items, pagination } = data
      return {
        data: items,
        count: pagination.total
      }
    })
};


export const createRound = (round) => {

  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const URL = `${process.env.REACT_APP_API_URL}/rounds`
  return axios.post(URL, round, config)
}

export const getRound = (id) => {

  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const URL = `${process.env.REACT_APP_API_URL}/rounds/${id}`
  return axios.get(URL, config)
    .then(({ data }) => data)
}

export const deleteRound = (id) => {

  const { token } = getProfile()

  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const URL = `${process.env.REACT_APP_API_URL}/rounds/${id}`
  return axios.delete(URL, config)
}

export const editRound = (id, round = {}) => {
  delete round.id

  const { token } = getProfile()

  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const URL = `${process.env.REACT_APP_API_URL}/rounds/${id}`
  return axios.put(URL, round, config)
}