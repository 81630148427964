import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Fab from '@material-ui/core/Fab';
import RoomIcon from '@material-ui/icons/Room';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MapCompanies from './MapCompanies';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
});

class MapModal extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { fullScreen } = this.props;
    const { open } = this.state;

    return (
      <div>
        <Fab color="primary" aria-label="map" size="medium" className="map-button" onClick={this.handleClickOpen}>
          <RoomIcon />
        </Fab>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth="lg"
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          className="modal"
        >
          <DialogTitle id="responsive-dialog-title" className="modal-title">
            <RoomIcon /> {"Intorno a me"}
            <IconButton aria-label="close" onClick={this.handleClose} className="btn-close">
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: '0' }}>
            <div className="cont-map">
              {
                open && <MapCompanies />
              }
            </div>
          </DialogContent>
        </Dialog>
      </div >
    );
  }
}

MapModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog(styles)(MapModal);