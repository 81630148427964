/* eslint-disable no-restricted-globals */
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { formatDateTime, formatTime, formatAddress } from '../../helpers/formatter';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const LeaveCatalog = ({ value }) => {

  if (!value) {
    return <div />
  }
  return (
    <Tooltip title="Lasciato catalogo: SI" placement="right">
      <div style={{ margin: '8px', height: '20px', width: '20px', backgroundColor: 'yellow' }} />
    </Tooltip>)
}

const NewClient = ({ value }) => {

  if (!value) {
    return <div />
  }
  return (
    <Tooltip title="Nuovo cliente: SI" placement="right">
      <div style={{ margin: '8px', height: '20px', width: '20px', backgroundColor: 'green' }} />
    </Tooltip>)
}



const RoundsTableRow = ({ round, onDelete, onEdit }) => {
  const { id, dateTime, name, roundType, note, username, endDateTime, leaveCatalog, newClient } = round || {}

  return (
    <TableRow key={id}>
      <TableCell className="no-padding first">
        <IconButton className="green" onClick={() => onEdit(id)}>
          <EditIcon />
        </IconButton>
        <IconButton className="red" onClick={() => {
          if (confirm('Vuoi davvero eliminare questa visita?')) {
            onDelete(id)
          }
        }}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <TableCell className="no-padding">
        <LeaveCatalog value={leaveCatalog} />
        <NewClient value={newClient} />
      </TableCell>
      <TableCell className="no-padding">{formatDateTime(dateTime)}</TableCell>
      <TableCell className="no-padding">{formatTime(endDateTime)}</TableCell>
      <TableCell className="no-padding">{name}</TableCell>
      <TableCell className="upper-case no-padding">{roundType}</TableCell>
      <TableCell className="no-padding">{formatAddress(round)}</TableCell>
      <TableCell className="no-padding">{username}</TableCell>
      <TableCell className="no-padding">{note}</TableCell>
    </TableRow>
  )
}

export default RoundsTableRow