import React, { Component } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import logo from './../images/logo-sinko.svg';
import { login } from '../api/auth';
import { setProfile, isLogged } from '../helpers/storage';
import packageJson from './../../package.json';

class Login extends Component {
  state = {
    username: '',
    password: ''
  }

  componentDidMount() {
    if (isLogged()) {
      this.props.history.push('/list')
    }
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = () => {
    this.setState({ error: '' })
    login({ username: this.state.username, password: this.state.password })
      .then(({ data }) => {
        setProfile(data)
        this.props.history.push('/list')
      })
      .catch(err => {
        this.setState({ error: 'Credenziali errate' })
      })
  }

  render() {
    const { username, password } = this.state;

    return (
      <div>
        <section>
          <Grid container spacing={16} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} className="text-center">
              <img src={logo} alt="Sinko srl" className="logo-login" />
            </Grid>
          </Grid>
        </section>
        <section>
          <Grid container spacing={16} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={6} className="text-center">
              <Card className="card-login">
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleSubmit}
                  onError={errors => console.log(errors)}
                  className="form-login"
                >
                  <CardContent>
                    <div>
                      <p className="red">{this.state.error}</p>
                    </div>
                    <TextValidator
                      InputLabelProps={{
                        FormLabelClasses: {
                          focused: 'form-login-field_label-focus',
                        },
                      }}
                      className="form-login-field"
                      label="Nome utente"
                      onChange={this.handleChange}
                      name="username"
                      value={username}
                      validators={['required']}
                      errorMessages={['Campo obbligatorio']}
                    />
                    <TextValidator
                      InputLabelProps={{
                        FormLabelClasses: {
                          focused: 'form-login-field_label-focus',
                        },
                      }}
                      className="form-login-field"
                      label="Password"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      validators={['required']}
                      errorMessages={['Campo obbligatorio']}
                      value={password}
                    />
                  </CardContent>
                  <CardActions>
                    {/* <Link to="/list" className="m-auto" style={{ textDecoration: 'none' }}> */}
                    <Button variant="contained" color="default" className="btn-sinko" type="submit">
                      Login
                        <ArrowForwardIcon />
                    </Button>
                    {/* </Link> */}
                  </CardActions>
                </ValidatorForm>
              </Card>
            </Grid>

            <Grid item xs={12} className="text-center">
              <Typography color="textSecondary" variant="h5" component="h5">
                {packageJson.version}
              </Typography>
            </Grid>
          </Grid>
        </section>
      </div>
    );
  }
}

export default Login;
