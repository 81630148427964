import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { formatDateTime } from '../helpers/formatter';

const getClass = (days) => {
  if (days === -1) return 'marker3'
  if (days < 30) return 'marker1'
  if (days < 60) return 'marker2'
  return 'marker3'
}
class Marker extends React.Component {
  state = {
    open: false,
  };

  openNavigator() {
    const { latitude, longitude } = this.props.company
    if ((navigator.platform.indexOf("iPhone") !== -1) ||
      (navigator.platform.indexOf("iPad") !== -1) ||
      (navigator.platform.indexOf("iPod") !== -1)) {

      window.open(`maps://?q=${latitude},${longitude}`, '_system');
    }
    else {
      window.open(`https://maps.google.com/maps?daddr=${latitude},${longitude}`, '_system');
    }
  }

  toogleOpen() {
    const { company, setOpen, openId } = this.props;
    const { id } = company || {};
    setOpen(openId === id ? '' : id)
  }
  render() {
    const { company, openId } = this.props;
    const { name, id, daysFromLastVisit, lastVisitDate } = company || {};

    return (
      <div className="cont-marker">
        {
          openId === id &&
          <div className="info-box">
            <div className="text-right">
              <CloseIcon onClick={() => this.toogleOpen()} />
            </div>
            <h2>{name}</h2>
            <p>Data ultima visita: {formatDateTime(lastVisitDate)}</p>
            <Button variant="contained" onClick={() => this.openNavigator()}>
              NAVIGA
            </Button>
          </div>
        }
        <RoomIcon
          className={getClass(daysFromLastVisit)}
          fontSize="large"
          onClick={() => this.toogleOpen()}
        />
      </div >
    );
  }
}

export default Marker;