import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { PROVINCES } from '../helpers/constants';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    // margin: theme.spacing.unit * 2,
    width: 'calc(100% - 8px)',
    marginTop: '16px',
  },
  formControlOnForm: {
    // margin: theme.spacing.unit * 2,
    // minWidth: 160,
    width: '100%',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '16px',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});


class SelectProvince extends React.Component {
  render() {
    const { province, onChangeProvince, classes, onForm = false } = this.props

    let formControl = classes.formControl
    if (onForm) {
      formControl = classes.formControlOnForm
    }
    return (
      <FormControl className={formControl}>
        <InputLabel htmlFor="select-province">
          Provincia
        </InputLabel>
        <Select
          value={province}
          onChange={(ev, { props }) => onChangeProvince(props.value)}
          inputProps={{
            name: 'select-province',
            id: 'select-province',
          }}
        >
          <MenuItem value="">
            <em> - </em>
          </MenuItem>
          {
            PROVINCES.map((option, k) => (
              <MenuItem key={k} value={option.value}>
                {option.value}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )
  }

}

export default withStyles(styles)(SelectProvince)