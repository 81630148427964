import React, { Component } from 'react';
import { getRound } from '../../api/rounds';
import Spinner from '../Spinner';
import FormRound from '../FormRound';
import isEmpty from 'lodash.isempty';

class EditRound extends Component {

  state = {
    loading: false,
    error: '',
    round: ''
  };

  componentDidMount() {
    const { id } = this.props

    if (!id) {
      this.setState({ loading: false, error: 'Qualcosa è andato storto', round: null })
      return
    }

    this.setState({ loading: true, error: '', round: null })

    getRound(id)
      .then(data => {
        this.setState({ loading: false, error: '', round: data })
      })
      .catch(err => {
        console.log(err)
        this.setState({ loading: false, error: JSON.stringify(err), round: null })
      })
  }

  render() {
    const { loading, error, round } = this.state
    const { onCancel, onSuccess } = this.props

    return (
      <div>
        {
          loading && <Spinner />
        }
        {
          error && <p className="red">{error}</p>
        }
        {
          !isEmpty(round) && (
            <FormRound
              onCancel={onCancel}
              onSuccess={onSuccess}
              initialValues={{ ...round }}
            />
          )
        }
      </div>
    );
  }
}

export default EditRound;