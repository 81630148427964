import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from './pages/Login';
import List from './pages/List';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const App = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#ACCB54',
      },
      secondary: {
        main: '#008e2a',
      }
    },
  });
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Router>
          <div>
            <Route path="/" exact component={Login} />
            <Route path="/list" component={List} />
          </div>
        </Router>
      </MuiThemeProvider>
    </div>
  )
}

export default App