import React from 'react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import { ROUND_TYPES, PROVINCES, REGIONS, CUSTOMER_TYPES, APPROACHES, TYPE } from '../helpers/constants';
import { datePickerProps, timePickerProps } from '../helpers/formatter';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import InputCompany from './InputCompany';
import CircularProgress from '@material-ui/core/CircularProgress';
import { emptyRound, prepareRound } from '../helpers/rounds';
import SelectAgent from './SelectAgent';
import { createRound, editRound } from '../api/rounds';
import { isAdmin } from '../helpers/storage';
import CoordinatesButtons from './CoordinatesButtons'
import moment from 'moment';
import 'moment/locale/it';
import RoundAttachments from './RoundAttachments';
moment.locale('it');

class FormRound extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ...emptyRound,
      error: '',
      loading: false,
      admin: false
    }

    this.setCoordinates = this.setCoordinates.bind(this)
  }

  componentDidMount() {
    const { initialValues } = this.props
    const admin = isAdmin()
    this.setState({ ...initialValues, admin })
  }

  changeForeign(value) {
    const updated = {
      foreign: value,
      region: value === '1' ? 'Estero' : '',
      country: '',
    }
    this.setState(updated)
  }

  onSubmit() {
    this.setState({ error: '', loading: true })

    const { admin } = this.state
    const round = prepareRound(this.state, admin)

    const { user, dateTime, endDateTime } = round

    if (!user) {
      this.setState({ error: 'Seleziona un\'agente', loading: false })
      return
    }
    if (!dateTime) {
      this.setState({ error: 'Seleziona una data', loading: false })
      return
    }

    if (!endDateTime) {
      this.setState({ error: 'Seleziona una data di fine visita', loading: false })
      return
    }

    const { initialValues = {} } = this.props
    const { id } = initialValues
    const promise = id ? editRound(id, round) : createRound(round)

    promise.then(() => {
      this.setState({ loading: false, ...emptyRound })
      this.props.onSuccess()
    })
      .catch(err => {
        console.log(err)
        this.setState({ error: JSON.stringify(err), loading: false })
      })
  }

  parseCheckbox(value) {
    return value ? '1' : '0'
  }

  setCoordinates(latitude, longitude ) {
    this.setState({ latitude, longitude })
  }

  render() {
    const { company, admin, dateTime, endDateTime, roundType, name, address, newClient = false, region, foreign, country, referenceName, phoneNumber, mail, km, typeCompany, type, offer, note, leaveCatalog, user, error, loading, rating, latitude, longitude } = this.state
    const { onCancel, initialValues } = this.props
    const edit = initialValues && initialValues.id

    return (
      <ValidatorForm
        className="form-visits"
        onSubmit={() => this.onSubmit()}
      >
        <Grid container spacing={40} direction="row" alignItems="center">
          {
            admin &&
            <Grid item xs={12} sm={12} md={3}>
              <SelectAgent
                className="input-vists"
                agent={user}
                onForm
                onChangeAgent={ev => this.setState({ user: ev.target.value })}
              />
            </Grid>
          }

          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale="it"
            moment={moment}
          >
            <Grid item xs={6} sm={6} md={3}>
              <DatePicker
                label="Data visita *"
                value={dateTime}
                className="input-vists"
                onChange={date => this.setState({ dateTime: date })}
                {...datePickerProps}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <TimePicker
                value={dateTime}
                label="Ora visita *"
                ampm={false}
                className="input-vists"
                onChange={date => this.setState({ dateTime: date })}
                {...timePickerProps}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale="it"
            moment={moment}
          >
            <Grid item xs={6} sm={6} md={3}>
              <TimePicker
                value={endDateTime}
                label="Ora fine visita *"
                ampm={false}
                className="input-vists"
                onChange={date => this.setState({ endDateTime: date })}
                {...timePickerProps}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid item xs={6} sm={6} md={6}>
            <SelectValidator
              label="Stato visita *"
              name="roundType"
              value={roundType}
              onChange={ev => this.setState({ roundType: ev.target.value })}
              className="input-vists"
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            >
              {
                ROUND_TYPES.map(type => (
                  <MenuItem key={type} value={type} className="upper-case">
                    {type}
                  </MenuItem>
                ))
              }
            </SelectValidator>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControl component="fieldset">
              <FormLabel>Nuovo cliente *</FormLabel>
              <RadioGroup
                row
                name="variant"
                aria-label="variant"
                value={this.parseCheckbox(newClient)}
                onChange={ev => this.setState({ newClient: ev.target.value === '1' })}
              >
                <FormControlLabel value="1" control={<Radio />} label="SI" />
                <FormControlLabel value="0" control={<Radio />} label="NO" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControl component="fieldset">
              <FormLabel>Estero</FormLabel>
              <RadioGroup
                row
                name="variant"
                aria-label="variant"
                value={foreign}
                onChange={ev => this.changeForeign(ev.target.value)}
              >
                <FormControlLabel value="1" control={<Radio />} label="SI" />
                <FormControlLabel value="0" control={<Radio />} label="NO" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={3} md={2}>
            <SelectValidator
              label="Tipo *"
              name="rating"
              value={rating}
              onChange={ev => this.setState({ rating: ev.target.value })}
              className="input-vists"
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            >
              {
                TYPE.map(option => (
                  <MenuItem key={option} value={option} className="upper-case">
                    {option}
                  </MenuItem>
                ))
              }
            </SelectValidator>
          </Grid>

          <Grid item xs={8} sm={9} md={4}>
            <InputCompany
              label={`Ragione Sociale * ${company ? '(ID:' + company + ')' : ''}`}
              className="input-vists"
              name={name}
              onChange={val => this.setState({ name: val, company: 0 })}
              onSelectCompany={company => this.setState({
                company: company.id,
                name: company.name,
                address: company.address,
                country: company.country,
                phoneNumber: company.phoneNumber,
                region: company.region,
                typeCompany: company.typeCompany,
                foreign: region === 'Estero' ? '1' : '0'
              })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
              className="input-vists"
              label="Indirizzo"
              name="address"
              value={address}
              onChange={ev => this.setState({ address: ev.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <SelectValidator
              label="Regione *"
              name="region"
              id="region"
              onChange={ev => this.setState({ region: ev.target.value, country: '' })}
              value={region}
              className="input-vists"
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            >
              {
                REGIONS.filter(r => foreign === '1' ? r === 'Estero' : r !== 'Estero').map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              }
            </SelectValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            {
              foreign === '1' ?
                <TextValidator
                  InputLabelProps={{
                    FormLabelClasses: {
                      focused: 'form-login-field_label-focus',
                    },
                  }}
                  className="input-vists"
                  label="Provincia *"
                  name="country"
                  value={country}
                  onChange={ev => this.setState({ country: ev.target.value })}
                  validators={['required']}
                  errorMessages={['Campo obbligatorio']}
                />
                :
                <SelectValidator
                  label="Provincia *"
                  name="country"
                  id="country"
                  onChange={ev => this.setState({ country: ev.target.value })}
                  value={country}
                  className="input-vists"
                  validators={['required']}
                  errorMessages={['Campo obbligatorio']}
                >
                  {
                    PROVINCES.filter(p => p.region === region).map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))
                  }
                </SelectValidator>
            }
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
              type="number"
              className="input-vists"
              label="Latitudine"
              name="latitude"
              value={latitude}
              onChange={ev => this.setState({ latitude: ev.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
              type="number"
              className="input-vists"
              label="Longitudine"
              name="longitude"
              value={longitude}
              onChange={ev => this.setState({ longitude: ev.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
              <CoordinatesButtons
                address={address}
                country={country}
                latitude={latitude}
                longitude={longitude}
                setCoordinates={this.setCoordinates} />
            </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
              className="input-vists"
              label="Riferimento"
              name="reference"
              value={referenceName}
              onChange={ev => this.setState({ referenceName: ev.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
              className="input-vists"
              label="Contatto telefonico"
              name="phone-number"
              value={phoneNumber}
              onChange={ev => this.setState({ phoneNumber: ev.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
              className="input-vists"
              label="Email"
              name="email"
              value={mail}
              validators={['isEmail']}
              errorMessages={['Inserisci un\'email valida']}
              onChange={ev => this.setState({ mail: ev.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField
              type="number"
              value={km}
              onChange={ev => this.setState({ km: ev.target.value })}
              label="Km percorsi"
              className="input-vists"
              InputProps={{
                startAdornment: <InputAdornment position="start">Km</InputAdornment>,
              }}
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <SelectValidator
              label="Tipologia cliente *"
              name="typeCompany"
              value={typeCompany}
              onChange={ev => this.setState({ typeCompany: ev.target.value })}
              className="input-vists"
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            >
              {
                CUSTOMER_TYPES.map(option => (
                  <MenuItem key={option} value={option} className="upper-case">
                    {option.toUpperCase()}
                  </MenuItem>
                ))}
            </SelectValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>

            <SelectValidator
              label="Tipo di approccio *"
              name="type"
              value={type}
              onChange={ev => this.setState({ type: ev.target.value })}
              className="input-vists"
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            >
              {
                APPROACHES.map(option => (
                  <MenuItem key={option} value={option} className="upper-case">
                    {option}
                  </MenuItem>
                ))
              }
            </SelectValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
              className="input-vists"
              label="Offerta"
              name="offer"
              value={offer}
              onChange={ev => this.setState({ offer: ev.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel>Carica allegato</FormLabel>
              <RoundAttachments roundId={initialValues && initialValues.id} />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={2}>
            <FormControl component="fieldset">
              <FormLabel>Catalogo lasciato *</FormLabel>
              <RadioGroup
                row
                name="variant"
                aria-label="variant"
                value={this.parseCheckbox(leaveCatalog)}
                onChange={ev => this.setState({ leaveCatalog: ev.target.value === '1' })}
              >
                <FormControlLabel value="1" control={<Radio />} label="SI" />
                <FormControlLabel value="0" control={<Radio />} label="NO" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={10}>
            <TextValidator
              InputLabelProps={{
                FormLabelClasses: {
                  focused: 'form-login-field_label-focus',
                },
              }}
              className="input-vists"
              label="Note *"
              name="note"
              value={note}
              onChange={ev => this.setState({ note: ev.target.value })}
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            />
          </Grid>

        </Grid>

        <Grid container spacing={40} direction="row" alignItems="center">
          <Grid item xs={12} className="text-center">
            {
              loading ? <CircularProgress /> :
                <div>
                  <Button type="submit" variant="contained" color="default" className="btn-sinko" style={{ marginTop: '40px' }}>
                    {edit ? 'Modifica visita' : 'Aggiungi visita'}
                    {edit ? <EditIcon /> : <AddIcon />}
                  </Button>
                  {
                    edit && (
                      <div>
                        <Button type="button" color="default" style={{ marginTop: '40px' }} onClick={onCancel}>
                          Annulla
                        </Button>
                      </div>
                    )
                  }
                </div>
            }
            {
              error && <p className="red">{error}</p>
            }
          </Grid>
        </Grid>

      </ValidatorForm>
    )
  }
}

export default FormRound