import dayjs from 'dayjs'

export const formatDateTime = (dateTime) => {
  const date = dayjs(dateTime)

  return date.isValid() ? date.format('DD-MM-YYYY HH:mm') : ' - '
}

export const formatTime = (dateTime) => {
  const date = dayjs(dateTime)

  return date.isValid() ? date.format('HH:mm') : ' - '
}

export const formatAddress = (round = {}) => {
  const { address = '', country = '', region = '' } = round
  return `${address}, ${country} (${region})`
}

export const datePickerProps = {
  clearable: true,
  clearLabel: 'Cancella',
  invalidLabel: '',
  keyboard: false,
  format: 'DD/MM/YYYY',
  autoOk: true,
  cancelLabel: 'Annulla'
}

export const timePickerProps = {
  clearable: true,
  clearLabel: 'Cancella',
  invalidLabel: '',
  keyboard: false,
  format: 'HH:mm',
  autoOk: true,
  cancelLabel: 'Annulla'
}