const PROFILE_KEY = 'sinko-profile'

export const setProfile = (profile) => {
    localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
}

export const getProfile = () => {
    return JSON.parse(localStorage.getItem(PROFILE_KEY) || '{}')
}

export const clearStorage = () => {
    localStorage.removeItem(PROFILE_KEY)
}

export const isLogged = () => {
    const profile = localStorage.getItem(PROFILE_KEY)
    return profile && isJSON(profile) ? true : false
}

export const isAdmin = () => {
    const { role } = getProfile()
    return role === 'admin'
}

const isJSON = (profile) => {
    try {
        JSON.parse(profile)
        return true
    } catch (e) {
        return false
    }
}