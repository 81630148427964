/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import { createAgent, editAgent } from '../api/agents';
import { emptyAgent } from '../helpers/agents';
import CircularProgress from '@material-ui/core/CircularProgress';
import pick from 'lodash.pick';

class FormAgents extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      ...emptyAgent,
      error: '',
      loading: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { initialValues } = this.props
    this.setState(initialValues)
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit() {
    this.setState({ loading: true, error: '' })

    const { initialValues = {} } = this.props
    const { id } = initialValues

    const agent = pick(this.state, ['nameSurname', 'username', 'password'])
    agent.role = 'agent'

    const promise = id ? editAgent(id, agent) : createAgent(agent)
    promise
      .then(() => {
        this.setState({ loading: false, ...emptyAgent })
        this.props.onSuccess()
      })
      .catch(err => {
        console.log(err)
        this.setState({ error: JSON.stringify(err), loading: false })
      })
  }

  render() {
    const { nameSurname, username, password, loading, error } = this.state
    const { initialValues, onCancel } = this.props
    const edit = initialValues && initialValues.id

    return (
      <div>
        <ValidatorForm className="form-visits" onSubmit={this.handleSubmit}>
          <Grid container spacing={40} direction="row" alignItems="center">

            <Grid item xs={12} sm={12} md={6}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Nome e Cognome *"
                name="nameSurname"
                onChange={this.handleChange}
                value={nameSurname}
                validators={['required']}
                errorMessages={['Campo obbligatorio']}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Username *"
                name="username"
                onChange={this.handleChange}
                value={username}
                validators={['required']}
                errorMessages={['Campo obbligatorio']}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Password *"
                name="password"
                onChange={this.handleChange}
                value={password}
                validators={['required']}
                errorMessages={['Campo obbligatorio']}
              />
            </Grid>

            <Grid item xs={12} sm={12} className="text-center">
              {
                loading ? <CircularProgress /> :
                  <div>
                    <Button type="submit" variant="contained" color="default" className="btn-sinko">
                      {edit ? 'Modifica agente' : 'Aggiungi agente'}
                      {edit ? <EditIcon /> : <AddIcon />}
                    </Button>
                    {
                      edit && (
                        <div>
                          <Button type="button" color="default" style={{ marginTop: '40px' }} onClick={onCancel}>
                            Annulla
                          </Button>
                        </div>
                      )
                    }
                  </div>
              }
              {
                error && <p className="red">{error}</p>
              }
            </Grid>

          </Grid>

        </ValidatorForm>
      </div>
    )
  }
}

export default FormAgents