/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit'
import { getCompany, editCompany } from '../api/companies';
import { emptyCompany, prepareCompany } from '../helpers/company';
import MenuItem from '@material-ui/core/MenuItem';
import CoordinatesButtons from './CoordinatesButtons'
import { PROVINCES, REGIONS, CUSTOMER_TYPES, TYPE } from '../helpers/constants';

class FormCompany extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      ...emptyCompany,
      error: '',
      loading: false,
      foreign: '0',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setCoordinates = this.setCoordinates.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props
    if (id === prevProps.id) { return; }

    if (!id) {
      this.setState({ loading: false, error: 'Qualcosa è andato storto', ...emptyCompany })
      return
    }

    this.setState({ loading: true, error: '', ...emptyCompany })

    getCompany(id)
      .then(data => {
        this.setState({ loading: false, error: '', ...data })
      })
      .catch(err => {
        console.log(err)
        this.setState({ loading: false, error: JSON.stringify(err), round: null })
      })
  }

  changeForeign(value) {
    const updated = {
      foreign: value,
      region: value === '1' ? 'Estero' : '',
      country: '',
    }
    this.setState(updated)
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit() {
    this.setState({ loading: true, error: '' })

    const company = prepareCompany(this.state)

    editCompany(this.props.id, company)
      .then(() => {
        this.setState({ loading: false, ...emptyCompany })
        this.props.onSuccess()
      })
      .catch(err => {
        console.log(err)
        this.setState({ error: JSON.stringify(err), loading: false })
      })
  }

  setCoordinates(latitude, longitude ) {
    this.setState({ latitude, longitude })
  }

  render() {
    const { name = '', address = '', country = '', phoneNumber = '', referenceName = '', region = '', mail = '', typeCompany = '', error = '', foreign = '0', latitude, longitude, rating } = this.state

    return (
      <div>
        <ValidatorForm className="form-visits" onSubmit={this.handleSubmit}>
          <Grid container spacing={40} direction="row" alignItems="center">

            <Grid item xs={4} sm={3} md={2}>
              <SelectValidator
                label="Tipo *"
                name="rating"
                value={rating}
                onChange={this.handleChange}
                className="input-vists"
                validators={['required']}
                errorMessages={['Campo obbligatorio']}
              >
                {
                  TYPE.map(option => (
                    <MenuItem key={option} value={option} className="upper-case">
                      {option}
                    </MenuItem>
                  ))
                }
              </SelectValidator>
            </Grid>

            <Grid item xs={8} sm={9} md={4}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Ragione Sociale"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Indirizzo"
                name="address"
                value={address}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <SelectValidator
                label="Regione *"
                name="region"
                id="region"
                onChange={this.handleChange}
                value={region}
                className="input-vists"
                validators={['required']}
                errorMessages={['Campo obbligatorio']}
              >
                {
                  REGIONS.filter(r => foreign === '1' ? r === 'Estero' : r !== 'Estero').map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                }
              </SelectValidator>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {
                foreign === '1' ?
                  <TextValidator
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused: 'form-login-field_label-focus',
                      },
                    }}
                    className="input-vists"
                    label="Provincia *"
                    name="country"
                    value={country}
                    onChange={this.handleChange}
                    validators={['required']}
                    errorMessages={['Campo obbligatorio']}
                  />
                  :
                  <SelectValidator
                    label="Provincia *"
                    name="country"
                    id="country"
                    onChange={this.handleChange}
                    value={country}
                    className="input-vists"
                    validators={['required']}
                    errorMessages={['Campo obbligatorio']}
                  >
                    {
                      PROVINCES.filter(p => p.region === region).map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))
                    }
                  </SelectValidator>
              }
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                type="number"
                className="input-vists"
                label="Latitudine"
                name="latitude"
                value={latitude}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                type="number"
                className="input-vists"
                label="Longitudine"
                name="longitude"
                value={longitude}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <CoordinatesButtons
                address={address}
                country={country}
                latitude={latitude}
                longitude={longitude}
                setCoordinates={this.setCoordinates} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Riferimento"
                name="referenceName"
                value={referenceName}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Contatto telefonico"
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextValidator
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: 'form-login-field_label-focus',
                  },
                }}
                className="input-vists"
                label="Email"
                name="mail"
                value={mail}
                validators={['isEmail']}
                errorMessages={['Inserisci un\'email valida']}
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <SelectValidator
                label="Tipologia cliente *"
                name="typeCompany"
                value={typeCompany}
                onChange={ev => this.setState({ typeCompany: ev.target.value })}
                className="input-vists"
                validators={['required']}
                errorMessages={['Campo obbligatorio']}
              >
                {
                  CUSTOMER_TYPES.map(option => (
                    <MenuItem key={option} value={option} className="upper-case">
                      {option.toUpperCase()}
                    </MenuItem>
                  ))}
              </SelectValidator>
            </Grid>

            {
              error && <p className="red">{error}</p>
            }

            <Button type="submit" variant="contained" color="default" className="btn-sinko" style={{ margin: 'auto', marginTop: '40px' }}>
              Modifica cliente <EditIcon />
            </Button>
          </Grid>
        </ValidatorForm>
      </div>
    )
  }
}

export default FormCompany