import pick from 'lodash.pick'

export const emptyCompany = {
  name: '',
  address: '',
  country: '',
  phoneNumber: '',
  referenceName: '',
  region: '',
  mail: '',
  typeCompany: '',
  rating: '',
  latitude: 0,
  longitude: 0,
}

export const prepareCompany = (raw) => {
  const company = pick(raw, ['name', 'address', 'country', 'phoneNumber', 'referenceName', 'region', 'mail', 'typeCompany', 'rating', 'latitude', 'longitude'])

  const { latitude = 0, longitude = 0 } = company;
  company.latitude = parseFloat(latitude || 0);
  company.longitude = parseFloat(longitude || 0);

  return company;
}
