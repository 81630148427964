import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { formatAddress, formatDateTime } from '../helpers/formatter';

export default ({ round, setRead }) => {
  const { dateTime, id, note, roundType = '', name } = round || {};

  return (
    <div key={id} className="memo-cont">
      <Chip label={roundType} className="visit-state" />
      <DialogContentText component="h3"><b>{name}</b> {formatAddress(round)}</DialogContentText>
      <DialogContentText component="h4">{formatDateTime(dateTime)}</DialogContentText>
      <DialogContentText>
        {note}
      </DialogContentText>
      <Button variant="contained" color="primary" onClick={() => setRead(id)}>
        OK
      </Button>
    </div>
  )
};