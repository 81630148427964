import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';

class RoundsTableHead extends React.Component {

  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="no-padding first">Azioni</TableCell>
          <TableCell>
            <TableSortLabel className="no-padding">Info</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel className="no-padding">Data - Ora inizio visita</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel className="no-padding">Ora fine visita</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel className="no-padding">Ragione sociale</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel className="no-padding">Stato visita</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel className="no-padding">Indirizzo</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel className="no-padding">Nome agente</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel className="no-padding">Note</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

export default RoundsTableHead