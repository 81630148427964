import axios from 'axios'
import { getProfile } from '../helpers/storage';

export const upload = (round_id, file) => {

  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data'
    },
    params: {
      round_id,
    },
  }

  const formData = new FormData();
  formData.append('files', file)

  const URL = `${process.env.REACT_APP_API_URL}/attachments`
  return axios.post(URL, formData, config)
}

export const deleteAttachment = (id) => {

  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const URL = `${process.env.REACT_APP_API_URL}/attachments/${id}`
  return axios.delete(URL, config)
}