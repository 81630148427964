/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table'
import { fetchCompanies } from '../api/companies'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class CompaniesTable extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      data: [],
      error: '',
    }
    this.fetch = this.fetch.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    this.setState({ isLoading: true })

    fetchCompanies({})
      .then(data => this.setState({ isLoading: false, error: '', data }))
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false, error: 'Qualcosa è andato storto', data: [] })
      })
  }

  render() {
    const { classes, onEdit } = this.props;
    const { isLoading, data } = this.state;

    return (
      <div>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <MaterialTable
              isLoading={isLoading}
              columns={[
                { title: 'Ragione Sociale', field: 'name' },
                {
                title: 'Indirizzo', field: 'address', render: company => <p>{company.address}, {company.region} ({company.country})</p>
                },
                { title: 'Referente', field: 'referenceName' },
                { title: 'Telefono', field: 'phoneNumber' },
                { title: 'Mail', field: 'mail' },
                { title: 'Tipo Cliente', field: 'typeCompany' },
              ]}
              data={data}
              title="Lista clienti"
              localization={{
                pagination: {
                  labelDisplayedRows: '{from}-{to} di {count}', // {from}-{to} of {count}
                  labelRowsPerPage: 'Righe per pagina:', // Rows per page:
                  firstAriaLabel: 'Prima pagina', // First Page
                  firstTooltip: 'Ultima pagina', // First Page
                  previousAriaLabel: 'Pagina precedente', // Previous Page
                  previousTooltip: 'Indietro', // Previous Page
                  nextAriaLabel: 'Pagina successiva', // Next Page
                  nextTooltip: 'Avanti', // Next Page
                  lastAriaLabel: 'Ultima pagina', // Last Page
                  lastTooltip: 'Ultima paginan', // Last Page
                },
                header: {
                  actions: 'Azioni',
                }
              }}
              options={{
                search: true,
                emptyRowsWhenPaging: false
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: 'Modifica',
                  onClick: (event, rowData) => onEdit(rowData),
                  iconProps: {
                    style: {
                      color: '#ACCB54',
                    },
                  },
                },
              ]}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

CompaniesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompaniesTable);