import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { upload, deleteAttachment } from '../api/attachments';
import { getRound } from '../api/rounds';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
/* eslint-disable no-restricted-globals */

const MAX_SIZE_MB = 50

class RoundAttachments extends React.Component {

  state = {
    loading: false,
    error: '',
    attachments: []
  }

  componentDidMount() {
    this.fetchAttachments();
  }

  onChange = (event) => {
    const { roundId } = this.props

    const file = event.target.files[0]
    const { size } = file
    event.target.value = null;

    if (size > MAX_SIZE_MB * 1000000) {
      this.setState({ error: `Il peso del file non può superare i ${MAX_SIZE_MB}MB` })
      return
    }

    this.setState({ loading: true, error: false })

    upload(roundId, file)
      .then(() => {
        this.setState({ loading: false, error: false })
        this.fetchAttachments()
      })
      .catch((err) => {
        this.setState({ loading: false, error: JSON.stringify(err) })
      })
  }

  fetchAttachments() {
    const { roundId } = this.props
    if (!roundId) { return; }

    this.setState({ loading: true })

    getRound(roundId)
      .then(data => {
        const { attachments = [] } = data;
        this.setState({ attachments, loading: false })
      })
      .catch(err => {
        console.log('Error in fetchAttachments', err);
        this.setState({ loading: false })
      });
  }

  deleteFile(id) {
    if (!confirm('Vuoi davvero eliminare questa allegato?')) {
      return;
    }

    if (this.state.loading) { return; }

    this.setState({ loading: true })

    deleteAttachment(id)
      .then(() => {
        this.setState({ loading: false, error: '' })
        this.fetchAttachments();
      })
      .catch(err => {
        console.log('Error in fetchAttachments', err);
        this.setState({ loading: false, error: JSON.stringify(err) })
      });
  }

  download(path) {
    window.open(path, '_blank')
  }

  render() {
    const { roundId } = this.props
    const { attachments, error, loading } = this.state;

    return (
      <div style={{ marginTop: '8px' }}>
        <input
          id={`file-${roundId}`}
          type="file"
          onChange={event => this.onChange(event)}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 1,
          }}
        />
        {
          !roundId ? (
            <Typography>
              Crea la visita per caricare file
            </Typography>
          ) : (
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item xs={2}>
                  {
                    loading ? <CircularProgress /> :
                      <Button variant="contained" className="btn-file" component="label" htmlFor={`file-${roundId}`}>
                        FILE
                      </Button>
                  }
                </Grid>
                <Grid item xs={10}>
                  {
                    attachments.map(({ file_name, id, path }) => (
                      <Typography className="attachment-cont" key={id} component="div">
                        <span onClick={() => this.download(path)} className="attachment-name">
                          <i>{file_name}</i>
                        </span>
                        <DeleteIcon
                          className="attachment-del"
                          color="error"
                          onClick={() => this.deleteFile(id)}
                        />
                      </Typography>
                    ))
                  }
                </Grid>
              </Grid>
            )
        }
        {
          error && (
            <Typography color="error">{error}</Typography>
          )
        }
      </div>
    )
  }
}

export default RoundAttachments