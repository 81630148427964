import React from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

class CoordinatesButtons extends React.Component {
  state = {
    error: '',
  }

  getCoordinates = () => {
    const { address, country, setCoordinates } = this.props

    const QUERY = `${country} ${address}`
    const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY

    axios
      .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${QUERY}&key=${API_KEY}`)
      .then(({ data }) => {
        const { results = [] } = data
        const { geometry = {} } = results[0]
        const { location = {} } = geometry
        const { lat: latitude = 0, lng: longitude = 0 } = location

        if (latitude === 0 && longitude === 0) {
          this.setError('Impossibile recuperare le coordinate, inserisci un indirizzo valido!')
          return
        }

        setCoordinates(latitude, longitude)
      })
      .catch(error => {
        this.setError('Impossibile recuperare le coordinate.')
        console.log(error)
      })
  }

  openNavigator = () => {
    const { latitude, longitude } = this.props

    if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('iPad') !== -1 || navigator.platform.indexOf('iPod') !== -1) {
      window.open(`maps://?q=${latitude},${longitude}`, '_system')
    } else {
      window.open(`https://maps.google.com/maps?daddr=${latitude},${longitude}`, '_system')
    }
  }

  setError = message => {
    this.setState({ error: message })
  }

  render() {
    const { address, country, latitude, longitude } = this.props
    const { error } = this.state

    return (
      <>
        <Button color="default" className="btn-sinko" style={{ width: '45%', marginRight: '5%' }} onClick={this.getCoordinates} disabled={!country || !address}>
          Recupera Coordinate
        </Button>
        <Button color="default" className="btn-sinko" style={{ width: '45%', marginLeft: '5%' }} onClick={this.openNavigator} disabled={!latitude || !longitude}>
          Apri Mappa
        </Button>

        {error && (
          <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={() => this.setError('')} aria-labelledby="responsive-dialog-title" className="modal">
            <DialogTitle id="responsive-dialog-title" className="modal-title">
              Errore
              <IconButton aria-label="close" onClick={() => this.setError('')} className="btn-close">
                <CloseIcon fontSize="small" />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: '0' }}>
              <div style={{ fontFamily: 'Helvetica, Arial', textAlign: 'center', fontSize: '20px', padding: '25px 25px' }}>{error}</div>
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  }
}

export default CoordinatesButtons
