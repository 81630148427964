import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RoundsTableToolbar from './RoundsTableToolbar';
import RoundsTableHead from './RoundsTableHead';
import RoundsTableRow from './RoundsTableRow';
import { fetchRounds, deleteRound } from '../../api/rounds';
import { getProfile, isAdmin } from '../../helpers/storage';
import moment from 'moment';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class RoundsTable extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      data: [],
      error: '',
      page: 0,
      perPage: 10,
      count: 0,
      startDate: moment().startOf('day'),
      endDate: moment().add(7, 'days').endOf('day'),
      agent: '',
      company: {},
      province: '',
      region: '',
      referenceName: '',
      admin: false
    }
  }

  componentDidMount() {
    const admin = isAdmin()
    this.setState({ admin })
    setTimeout(() => this.fetch())
  }

  fetch = () => {
    const { perPage, page, startDate, endDate, agent, admin, company, province, region, referenceName } = this.state

    let idAgent = agent
    if (!admin) {
      const { id } = getProfile()
      idAgent = id
    }

    const companyId = company && company.id
    this.setState({ isLoading: true })

    fetchRounds({ limit: perPage, offset: page * perPage, startDate, endDate, agent: idAgent, company: companyId, province, region, referenceName })
      .then(({ data = [], count = 0 }) => this.setState({ isLoading: false, error: '', data, count }))
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false, error: 'Qualcosa è andato storto', data: [] })
      })
  }

  deleteRound(id) {
    this.setState({ isLoading: true })

    deleteRound(id)
      .then(() => {
        this.fetch()
      })
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false, error: JSON.stringify(err) })
      })
  }

  changePage = (page) => {
    this.setState({ page });
    setTimeout(() => this.fetch())
  };

  changePerPage = (perPage) => {
    this.setState({ perPage })
    setTimeout(() => this.fetch())
  };

  changeStart = (date) => {
    this.setState({ startDate: date, page: 0 })
    setTimeout(() => this.fetch())
  }

  changeEnd = (date) => {
    this.setState({ endDate: date, page: 0 })
    setTimeout(() => this.fetch())
  }

  changeAgent = (agent) => {
    this.setState({ agent, page: 0 })
    setTimeout(() => this.fetch())
  }

  changeCompany = (company) => {
    this.setState({ company, page: 0 })
    setTimeout(() => this.fetch())
  }

  changeProvince = (province) => {
    this.setState({ province, page: 0 })
    setTimeout(() => this.fetch())
  }

  changeRegion = (region) => {
    this.setState({ region, page: 0 })
    setTimeout(() => this.fetch())
  }

  changeReferenceName = ({ referenceName }) => {
    console.log('referenceName', referenceName)
    // this.setState({ referenceName, page: 0 })
    // setTimeout(() => this.fetch())
  }

  clearFilter = () => {
    this.setState({ agent: '', company: '', startDate: '', endDate: '', page: 0 })
    setTimeout(() => this.fetch())
  }

  render() {
    const { classes, onEdit } = this.props;
    const { data, perPage, page, count, isLoading, startDate, endDate, agent, admin, company, province, region, referenceName } = this.state;

    return (
      <div>
        <Paper className={classes.root}>
          <RoundsTableToolbar
            isLoading={isLoading}
            startDate={startDate}
            endDate={endDate}
            isAdmin={admin}
            onChangeStart={(date) => this.changeStart(date)}
            onChangeEnd={(date) => this.changeEnd(date)}
            agent={agent}
            company={company}
            province={province}
            region={region}
            referenceName={referenceName}
            onChangeAgent={a => this.changeAgent(a)}
            onChangeCompany={a => this.changeCompany(a)}
            onChangeProvince={a => this.changeProvince(a)}
            onChangeRegion={a => this.changeRegion(a)}
            onChangeReferenceName={a => this.changeReferenceName(a)}
            onClearFilter={() => this.clearFilter()}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <RoundsTableHead />
              <TableBody>
                {
                  Array.isArray(data) && data.map((round = {}, index) => (
                    <RoundsTableRow
                      onDelete={id => this.deleteRound(id)}
                      onEdit={onEdit}
                      key={index}
                      round={round} />)
                  )
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="Visualizza"
                    rowsPerPageOptions={[1, 2, 5, 10, 25]}
                    count={count}
                    rowsPerPage={perPage}
                    page={page}
                    onChangePage={(ev, p) => this.changePage(p)}
                    onChangeRowsPerPage={ev => this.changePerPage(ev.target.value)}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(RoundsTable);