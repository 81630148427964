import clone from 'lodash.clone';
import { getProfile } from './storage';

export const emptyRound = {
  company: 0,
  address: '',
  country: '',
  dateTime: '',
  endDateTime: '',
  km: '',
  leaveCatalog: false,
  mail: '',
  name: '',
  newClient: false,
  note: '',
  offer: '',
  phoneNumber: '',
  referenceName: '',
  region: '',
  roundType: '',
  type: '',
  typeCompany: '',
  foreign: '0',
  user: '',
  rating: '',
  latitude: 0,
  longitude: 0,
}

export const prepareRound = (raw, isAdmin) => {
  const round = clone(raw)

  if (!isAdmin) {
    const { id } = getProfile()
    round.user = id
  }

  const { latitude = 0, longitude = 0 } = round;
  round.latitude = parseFloat(latitude || 0);
  round.longitude = parseFloat(longitude || 0);

  return round
}

// ACTIVITIES
export const ROUND_READ_IDS = []

export const setRoundRead = (roundId) => {
  ROUND_READ_IDS.push(roundId);
}