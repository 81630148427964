export const ROUND_TYPES = [
  'ricerca nuovi clienti',
  'primo contatto',
  'trattativa',
  'rilievi',
  'visita periodica',
  'pianificata',
]

export const TYPE = [
  'A',
  'B',
  'C'
];

export const APPROACHES = [
  'vedi persona',
  'telefonata'
];

export const CUSTOMER_TYPES = [
  'studio progettazione',
  'contract',
  'rivenditore',
  'cliente finale'
];

export const REGIONS = [
  'Estero', 'Abruzzo', 'Basilicata',
  'Calabria', 'Campania', 'Emilia Romagna', 'Friuli Venezia Giulia',
  'Lazio', 'Liguria', 'Lombardia', 'Marche', 'Molise',
  'Piemonte', 'Puglia', 'Sardegna', 'Sicilia', 'Toscana', 'Trentino Alto Adige',
  'Umbria', 'Valle d\'Aosta', 'Veneto',
];

export const PROVINCES = [
  { value: "Agrigento", region: "Sicilia" },
  { value: "Alessandria", region: "Piemonte" },
  { value: "Ancona", region: "Marche" },
  { value: "Aosta", region: "Valle d'Aosta" },
  { value: "L'Aquila", region: "Abruzzo" },
  { value: "Arezzo", region: "Toscana" },
  { value: "Ascoli-Piceno", region: "Marche" },
  { value: "Asti", region: "Piemonte" },
  { value: "Avellino", region: "Campania" },
  { value: "Bari", region: "Puglia" },
  { value: "Barletta-Andria-Trani", region: "Puglia" },
  { value: "Belluno", region: "Veneto" },
  { value: "Benevento", region: "Campania" },
  { value: "Bergamo", region: "Lombardia" },
  { value: "Biella", region: "Piemonte" },
  { value: "Bologna", region: "Emilia Romagna" },
  { value: "Bolzano", region: "Trentino Alto Adige" },
  { value: "Brescia", region: "Lombardia" },
  { value: "Brindisi", region: "Puglia" },
  { value: "Cagliari", region: "Sardegna" },
  { value: "Caltanissetta", region: "Sicilia" },
  { value: "Campobasso", region: "Molise" },
  { value: "Carbonia Iglesias", region: "Sardegna" },
  { value: "Caserta", region: "Campania" },
  { value: "Catania", region: "Sicilia" },
  { value: "Catanzaro", region: "Calabria" },
  { value: "Chieti", region: "Abruzzo" },
  { value: "Como", region: "Lombardia" },
  { value: "Cosenza", region: "Calabria" },
  { value: "Cremona", region: "Lombardia" },
  { value: "Crotone", region: "Calabria" },
  { value: "Cuneo", region: "Piemonte" },
  { value: "Enna", region: "Sicilia" },
  { value: "Fermo", region: "Marche" },
  { value: "Ferrara", region: "Emilia Romagna" },
  { value: "Firenze", region: "Toscana" },
  { value: "Foggia", region: "Puglia" },
  { value: "Forli-Cesena", region: "Emilia Romagna" },
  { value: "Frosinone", region: "Lazio" },
  { value: "Genova", region: "Liguria" },
  { value: "Gorizia", region: "Friuli Venezia Giulia" },
  { value: "Grosseto", region: "Toscana" },
  { value: "Imperia", region: "Liguria" },
  { value: "Isernia", region: "Molise" },
  { value: "La-Spezia", region: "Liguria" },
  { value: "Latina", region: "Lazio" },
  { value: "Lecce", region: "Puglia" },
  { value: "Lecco", region: "Lombardia" },
  { value: "Livorno", region: "Toscana" },
  { value: "Lodi", region: "Lombardia" },
  { value: "Lucca", region: "Toscana" },
  { value: "Macerata", region: "Marche" },
  { value: "Mantova", region: "Lombardia" },
  { value: "Massa-Carrara", region: "Toscana" },
  { value: "Matera", region: "Basilicata" },
  { value: "Medio Campidano", region: "Sardegna" },
  { value: "Messina", region: "Sicilia" },
  { value: "Milano", region: "Lombardia" },
  { value: "Modena", region: "Emilia Romagna" },
  { value: "Monza-Brianza", region: "Lombardia" },
  { value: "Napoli", region: "Campania" },
  { value: "Novara", region: "Piemonte" },
  { value: "Nuoro", region: "Sardegna" },
  { value: "Ogliastra", region: "Sardegna" },
  { value: "Olbia Tempio", region: "Sardegna" },
  { value: "Oristano", region: "Sardegna" },
  { value: "Padova", region: "Veneto" },
  { value: "Palermo", region: "Sicilia" },
  { value: "Parma", region: "Emilia Romagna" },
  { value: "Pavia", region: "Lombardia" },
  { value: "Perugia", region: "Umbria" },
  { value: "Pesaro-Urbino", region: "Marche" },
  { value: "Pescara", region: "Abruzzo" },
  { value: "Piacenza", region: "Emilia Romagna" },
  { value: "Pisa", region: "Toscana" },
  { value: "Pistoia", region: "Toscana" },
  { value: "Pordenone", region: "Friuli Venezia Giulia" },
  { value: "Potenza", region: "Basilicata" },
  { value: "Prato", region: "Toscana" },
  { value: "Ragusa", region: "Sicilia" },
  { value: "Ravenna", region: "Emilia Romagna" },
  { value: "Reggio-Calabria", region: "Calabria" },
  { value: "Reggio-Emilia", region: "Emilia Romagna" },
  { value: "Rieti", region: "Lazio" },
  { value: "Rimini", region: "Emilia Romagna" },
  { value: "Roma", region: "Lazio" },
  { value: "Rovigo", region: "Veneto" },
  { value: "Salerno", region: "Campania" },
  { value: "Sassari", region: "Sardegna" },
  { value: "Savona", region: "Liguria" },
  { value: "Siena", region: "Toscana" },
  { value: "Siracusa", region: "Sicilia" },
  { value: "Sondrio", region: "Lombardia" },
  { value: "Taranto", region: "Puglia" },
  { value: "Teramo", region: "Abruzzo" },
  { value: "Terni", region: "Umbria" },
  { value: "Torino", region: "Piemonte" },
  { value: "Trapani", region: "Sicilia" },
  { value: "Trento", region: "Trentino Alto Adige" },
  { value: "Treviso", region: "Veneto" },
  { value: "Trieste", region: "Friuli Venezia Giulia" },
  { value: "Udine", region: "Friuli Venezia Giulia" },
  { value: "Varese", region: "Lombardia" },
  { value: "Venezia", region: "Veneto" },
  { value: "Verbania", region: "Piemonte" },
  { value: "Vercelli", region: "Piemonte" },
  { value: "Verona", region: "Veneto" },
  { value: "Vibo-Valentia", region: "Calabria" },
  { value: "Vicenza", region: "Veneto" },
  { value: "Viterbo", region: "Lazio" },
];