import React, { Component, Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormRound from '../components/FormRound'
import FormAgents from '../components/FormAgents';
import AgentsTable from '../components/AgentsTable';
import Header from '../components/Header';
import { withStyles } from '@material-ui/core/styles';
import RoundsTable from '../components/Rounds/RoundsTable';
import { emptyRound } from '../helpers/rounds';
import EditRound from '../components/Rounds/EditRound';
import { isLogged, isAdmin } from '../helpers/storage';
import { emptyAgent } from '../helpers/agents';
import isEmpty from 'lodash.isempty';
import CompaniesTable from '../components/CompaniesTable';
import FormCompany from '../components/FormCompany';

const styles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  hide: {
    display: 'none'
  },
  show: {
    display: 'visible'
  }
})

const getContStyle = (current, index) => ({ display: current === index ? null : 'none' })

class List extends Component {

  constructor(props) {
    super(props)
    this.state = {
      index: 1,
      roundId: 0, //id della visita da editare
      companyId: 0, //id della company da editare
      agent: {}, //agente da editare
      isAdmin: false,
    };
    this.refRounds = React.createRef();
    this.refAgents = React.createRef();
    this.refCompanies = React.createRef();
  }

  componentDidMount() {
    if (!isLogged()) {
      this.props.history.push('/')
    }

    if (isAdmin()) {
      this.setState({ isAdmin: true })
    }
  }

  handleChangeIndex = index => {
    window.scrollTo(0, 0)
    this.setState({ index });
  };

  onRoundSubmit = () => {
    this.handleChangeIndex(1)
    this.refRounds.current.fetch()
  }

  onCompanySubmit = () => {
    this.handleChangeIndex(3)
    this.refCompanies.current.fetch()
    this.setState({ companyId: 0 })
  }

  onAgentSubmit = () => {
    this.handleChangeIndex(5)
    this.refAgents.current.fetch()
  }

  render() {
    const { index, roundId, isAdmin, agent, companyId } = this.state
    const { classes } = this.props;

    return (
      <div>
        <Header />
        <section>
          <div>
            <AppBar position="fixed" color="primary" className={classes.appBar}>
              <Tabs
                value={index}
                onChange={(ev, index) => this.handleChangeIndex(index)}
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                className="custom-label-color"
              >

                <Tab label="Edit visite" style={{ display: 'none' }} disabled />
                <Tab label="Lista visite" className="font-tab" />
                <Tab label="Aggiungi visita" className="font-tab" />
                <Tab label="Lista clienti" className="font-tab" />
                <Tab label="Dettaglio cliente" className="font-tab" style={{ display: 'none' }} disabled />
                {
                  isAdmin && <Tab label="Lista utenti agenti" className="font-tab" />
                }
                {
                  isAdmin && <Tab label="Aggiungi utente agente" className="font-tab" />
                }
                {
                  isAdmin && <Tab label="Edit user" style={{ display: 'none' }} disabled />
                }
              </Tabs>
            </AppBar>

            {
              index === 0 && roundId && (
                <div className="content-tab">
                  <EditRound
                    id={roundId}
                    onSuccess={() => this.onRoundSubmit()}
                    onCancel={() => this.setState({ index: 1 })}
                  />
                </div>
              )
            }

            <div className="content-tab" style={getContStyle(index, 1)}>
              <RoundsTable
                innerRef={this.refRounds}
                onEdit={id => this.setState({ index: 0, roundId: id })}
              />
            </div>
            <div className="content-tab" style={getContStyle(index, 2)}>
              <FormRound
                initialValues={emptyRound}
                onSuccess={() => this.onRoundSubmit()}
              />
            </div>
            <div className="content-tab" style={getContStyle(index, 3)}>
              <CompaniesTable
                innerRef={this.refCompanies}
                onEdit={company => this.setState({ index: 4, companyId: company.id })}
              />
            </div>
            <div className="content-tab" style={getContStyle(index, 4)}>
              <FormCompany
                id={companyId}
                initialValues={emptyRound}
                onSuccess={() => this.onCompanySubmit()}
              />
            </div>
            {
              isAdmin && (
                <Fragment>
                  <div className="content-tab" style={getContStyle(index, 5)}>
                    <AgentsTable
                      innerRef={this.refAgents}
                      onEdit={agent => this.setState({ index: 7, agent })}
                    />
                  </div>
                  <div className="content-tab" style={getContStyle(index, 6)}>
                    <FormAgents
                      initialValues={emptyAgent}
                      onSuccess={() => this.onAgentSubmit()}
                    />
                  </div>
                </Fragment>
              )
            }
            {
              isAdmin && index === 7 && !isEmpty(agent) && (
                <div className="content-tab">
                  <FormAgents
                    initialValues={agent}
                    onSuccess={() => this.onAgentSubmit()}
                    onCancel={() => this.setState({ index: 5 })}
                  />
                </div>
              )
            }

          </div>
        </section>
      </div >
    );
  }
}

export default withStyles(styles)(List);