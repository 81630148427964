/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table'
import { fetchAgents, deleteAgent } from './../api/agents'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class AgentsTable extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      data: [],
      error: '',
    }
    this.deleteAgent = this.deleteAgent.bind(this)
    this.fetch = this.fetch.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = () => {
    this.setState({ isLoading: true })

    fetchAgents()
      .then(data => this.setState({ isLoading: false, error: '', data }))
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false, error: 'Qualcosa è andato storto', data: [] })
      })
  }

  deleteAgent(agent) {
    this.setState({ isLoading: true })

    deleteAgent(agent)
      .then(() => {
        this.setState({ isLoading: false, error: '' })
      })
      .then(this.fetch)
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false, error: 'Qualcosa è andato storto', data: [] })
      })
  }

  render() {
    const { classes, onEdit } = this.props;
    const { isLoading, data } = this.state;

    return (
      <div>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <MaterialTable
              isLoading={isLoading}
              columns={[
                { title: 'Nome e Cognome', field: 'nameSurname' },
                { title: 'Username', field: 'username' },
              ]}
              data={data}
              title="Lista utenti agenti"
              localization={{
                pagination: {
                  labelDisplayedRows: '{from}-{to} di {count}', // {from}-{to} of {count}
                  labelRowsPerPage: 'Righe per pagina:', // Rows per page:
                  firstAriaLabel: 'Prima pagina', // First Page
                  firstTooltip: 'Ultima pagina', // First Page
                  previousAriaLabel: 'Pagina precedente', // Previous Page
                  previousTooltip: 'Indietro', // Previous Page
                  nextAriaLabel: 'Pagina successiva', // Next Page
                  nextTooltip: 'Avanti', // Next Page
                  lastAriaLabel: 'Ultima pagina', // Last Page
                  lastTooltip: 'Ultima paginan', // Last Page
                },
                header: {
                  actions: 'Azioni',
                }
              }}
              options={{
                search: false,
                emptyRowsWhenPaging: false
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: 'Modifica',
                  onClick: (event, rowData) => onEdit(rowData),
                  iconProps: {
                    style: {
                      color: '#ACCB54',
                    },
                  },
                },
                {
                  icon: "delete",
                  tooltip: 'Cancella',
                  onClick: (event, rowData) => {
                    if (confirm('Vuoi davvero eliminare questo utente?')) {
                      this.deleteAgent(rowData)
                    }
                  },
                  iconProps: {
                    style: {
                      color: '#F25757',
                    },
                  },
                },
              ]}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

AgentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AgentsTable);