import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import MyMarker from './MyMarker';
import { geolocated } from "react-geolocated";
import { DialogContentText } from '@material-ui/core';
import { getCompaniesClose } from '../api/companies';
const ROME = { lat: 41.9109, lng: 12.4818 };

class MapCompanies extends React.Component {
  state = {
    data: false,
    error: '',
    openId: 0
  };

  componentDidUpdate(prevProps) {
    const { coords } = this.props;
    const { coords: prevCoords = {} } = prevProps;

    if (!coords || !coords.latitude || !coords.longitude
      || (prevCoords && coords.latitude === prevCoords.latitude && coords.longitude === prevCoords.longitude)) {
      return;
    }
    this.fetch();
  }

  fetch() {
    const { coords, positionError } = this.props;
    const { latitude, longitude } = coords;

    if (positionError) {
      this.setState({ error: JSON.stringify(positionError) })
      return;
    }

    getCompaniesClose(latitude, longitude)
      .then(data => {
        this.setState({ error: '', data })
      })
      .catch(err => {
        console.log(err)
        this.setState({ error: JSON.stringify(err), data: [] })
      })
  }

  setOpen(roundId) {
    this.setState({ openId: roundId })
  }

  render() {
    const { error, data, openId } = this.state
    const { isGeolocationAvailable, isGeolocationEnabled, coords } = this.props

    if (!isGeolocationAvailable) {
      return <DialogContentText className="map-error-text"><b>ATTENZIONE: </b>il tuo browser non supporta la localizzazione</DialogContentText>
    }

    if (isGeolocationAvailable && !isGeolocationEnabled) {
      return <DialogContentText className="map-error-text"><b>ATTENZIONE: </b>attiva la Geolocalizzazione</DialogContentText>
    }

    if (error) {
      return <DialogContentText className="map-error-text">{error}</DialogContentText>
    }

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        defaultCenter={ROME}
        center={coords ? { lat: coords.latitude, lng: coords.longitude } : null}
        defaultZoom={6}
        zoom={coords ? 10 : 6}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        {
          coords && (
            <MyMarker
              lat={coords.latitude}
              lng={coords.longitude} />
          )
        }
        {
          data && data.map(company => (
            <Marker
              key={company.id}
              lat={company.latitude}
              lng={company.longitude}
              company={company}
              openId={openId}
              setOpen={id => this.setOpen(id)}
            />
          ))
        }
      </GoogleMapReact>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
})(MapCompanies);