import axios from 'axios'
import { getProfile } from '../helpers/storage';

export const fetchCompanies = ({ name = '', sort = '' }) => {

  const { token } = getProfile()

  const config = {
    params: {
      filter: {
        where: {
          name: { like: `%${name}%` }
        }
      },
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  if (sort) {
    config.params.filter.order = sort
  }

  const URL = `${process.env.REACT_APP_API_URL}/companies`
  return axios.get(URL, config)
    .then(({ data = [] }) => data)
    .catch(err => {
      return [
        { id: 1, name: "Company 1", address: 'Via 1' },
        { id: 2, name: "Company 2", address: 'Via 2' }
      ]
    })
};

export const editCompany = (id, company) => {
  delete company.id

  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const URL = `${process.env.REACT_APP_API_URL}/companies/${id}`
  return axios.put(URL, company, config)
}

export const getCompany = (id) => {

  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const URL = `${process.env.REACT_APP_API_URL}/companies/${id}`
  return axios.get(URL, config)
    .then(({ data }) => data)
}

export const getCompaniesClose = (latitude = 0, longitude = 0) => {

  const { token } = getProfile()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      latitude,
      longitude,
    },
  }

  const URL = `${process.env.REACT_APP_API_URL}/companies/close`
  return axios.get(URL, config)
    .then(({ data }) => data)
}