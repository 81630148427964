import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { fetchAgents } from '../api/agents';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    // margin: theme.spacing.unit * 2,
    width: 'calc(100% - 8px)',
    marginTop: '16px',
  },
  formControlOnForm: {
    // margin: theme.spacing.unit * 2,
    // minWidth: 160,
    width: '100%',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '16px',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});


class SelectAgent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      agents: []
    }
  }

  componentDidMount() {
    fetchAgents()
      .then(data => this.setState({ agents: data }))
  }

  render() {
    const { agent, onChangeAgent, classes, onForm = false } = this.props
    const { agents = [] } = this.state

    let formControl = classes.formControl
    if (onForm) {
      formControl = classes.formControlOnForm
    }
    return (
      <FormControl className={formControl}>
        <InputLabel htmlFor="select-agent">
          Agente
        </InputLabel>
        <Select
          value={agent}
          onChange={onChangeAgent}
          inputProps={{
            name: 'select-agent',
            id: 'select-agent',
          }}
        >
          <MenuItem value="">
            <em> - </em>
          </MenuItem>
          {
            Array.isArray(agents) && agents.map(({ id, nameSurname }) => (
              <MenuItem key={id} value={id}>
                {nameSurname}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    )
  }

}

export default withStyles(styles)(SelectAgent)