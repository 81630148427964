import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import { fetchCompanies } from '../api/companies';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator } from 'react-material-ui-form-validator';

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      {suggestion.name}
    </MenuItem>
  );
}

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextValidator
      InputLabelProps={{
        FormLabelClasses: {
          focused: 'form-login-field_label-focus',
        },
      }}
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
      className="input-vists"
      name="name"
      validators={['required']}
      errorMessages={['Campo obbligatorio']}
    />
  );
}


const styles = theme => ({
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class InputCompany extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      companies: []
    }
  }

  getCompanies({ value }) {
    if (value.length < 2) return

    fetchCompanies({ name: value })
      .then(companies => this.setState({ companies }))
  }

  render() {
    const { name, onChange, onSelectCompany, classes, label } = this.props
    const { companies } = this.state

    return (
      <Autosuggest
        onSuggestionsFetchRequested={result => this.getCompanies(result)}
        suggestions={companies}
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={suggestion => suggestion.name}
        onSuggestionsClearRequested={() => this.setState({ companies: [] })}
        onSuggestionSelected={(event, { suggestion }) => onSelectCompany(suggestion)}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        inputProps={{
          classes,
          label,
          placeholder: 'Cerca azienda',
          value: name,
          onChange: (ev, { newValue }) => onChange(newValue),
          InputLabelProps: {
            shrink: true,
          },
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    )
  }
}

export default withStyles(styles)(InputCompany);
