import React from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getProfile, clearStorage } from '../helpers/storage';
import { withRouter } from "react-router";
import logo from './../images/logo-sinko.svg';
import ActivitiesModal from './ActivitiesModal';

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
  }

  logout() {
    clearStorage()
    this.props.history.push('/')
  }

  render() {
    const { nameSurname } = getProfile()
    return (
      <header className="my-header">
        <Grid container spacing={40} className="container-1">
          <Grid item xs={6} className="item-in">
            <img src={logo} alt="Sinko srl" />
          </Grid>
          <Grid item xs={6} className="item-in">
            <Typography color="textSecondary" variant="h5" component="h5" style={{ height: '100%' }}>
              <ActivitiesModal />
              Ciao, {nameSurname}!
              <Button onClick={this.logout} className="logout-btn">
                Logout
              </Button>
            </Typography>

          </Grid>
        </Grid>
      </header>
    )
  }
}

export default withRouter(Header)